import React, { useState, useEffect } from 'react'
import { useLocation, useParams } from 'react-router';
import { fetchXLSX2JSON } from '../apiCalls';
import Markdown from './Markdown';

import Header from './Header';
import MathJaxProvider from '../Providers/MathJaxProvider';

const Container = () => {
    let { file } = useParams();
    const { search } = useLocation();
    const theme = new URLSearchParams(search).get("theme");

    const [data, setData] = useState(null);

    useEffect(() => {
        if (theme) {
            const htmlEle = document.querySelector('html');
            htmlEle.dataset.theme = theme;
        }
        if (file.length > 0) {
            fetchXLSX2JSON(file).then((csvData) => {
                setData(csvData);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [file]);

    return (
        <div id='container'>
            <Header />
            {file.length > 0 && (
                <MathJaxProvider
                    url="./mathjax3/tex-chtml.js"
                    options={{
                        tex: {
                            displayMath: [['$$', '$$']],
                            inlineMath: [['\\start', '\\end'], ['\\(', '\\)']]
                        }
                    }}>
                    <Markdown file={file} data={data} />
                </MathJaxProvider>
            )}
        </div>
    )
}

export default Container
