import React from 'react';
import './normalize.css';
import './style.css';

import {
  HashRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Container from './components/Container';
import CheatSheet from './pages/CheatSheet';
import NotFound from './pages/NotFound';



const App = () => {
  return (
    <Router>
      <div className="wrapper markdown-body">
        <Switch>
          <Route exact path='/:file' component={Container} />
          <Route exact path='/cheatsheet' component={CheatSheet} />
          <Route path="*" component={NotFound}/>
        </Switch>
      </div>
    </Router>

  )
}

export default App
