import React from 'react'
import DataTable from './DataTable';
import Message from './Message';


const CustomTag = ({children, data}) => {
    const classname = children[0].split(' ')[0].replace(/::/g, "");
    const regex = new RegExp(`::${classname}`, "g");
    const contentText = children[0].replace(regex, "").trim();
    switch(classname) {
        case 'info': return (<Message>{contentText}</Message>);
        case 'table': return (<DataTable context={contentText} data={data}/>);
        default: return(
            <div className={classname}>
                <p>
                    {contentText}
                </p>
            </div>
        )
    }
}


export default CustomTag
