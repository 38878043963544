import React, { useEffect, useState } from 'react'
import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm'
import { fetchMDContent } from '../apiCalls';
import CustomTag from './CustomTag';
import { useMathJax } from '../Providers/useMathJax';
import Spinner from './Spinner';

const Markdown = ({ file,data }) => {
    const [markdown, setMarkdown] = useState("");
    const [MathJax, updateMathContent] = useMathJax();
    const [spinner, setSpinner] = useState(false);

    useEffect(() => {
        fetchMDContent(file).then((data) => {
            setMarkdown(data);
            setSpinner(true);
        });
        updateMathContent();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const components = {
        p: ({node, inline, children, ...props}) => {
            return typeof children[0] === 'string' && children[0].match(/::/) ? (
                <CustomTag data={data}>{children}</CustomTag>
              ) : (
                <p{...props}>
                  {children}
                </p>
              )
        },
        code : ({node, inline, className, children, ...props}) => {
            const match = /language-(\w+)/.exec(className || '')
            return !inline && match ? (
                <div className='mathml' dangerouslySetInnerHTML={{ __html: children }}></div>
            ) : (
              <code className={className} {...props}>
                {children}
              </code>
            )
          }
    }
    if (!MathJax) return null;
    return (
        <>{spinner ? <ReactMarkdown children={markdown} components={components} remarkPlugins={[remarkGfm]}/> :
        <Spinner/>


        }
            
        </>
    )
}

export default Markdown
