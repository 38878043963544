import React from 'react';
import Header from '../components/Header';
import warning from '../images/warning.svg'


const NotFound = () => {
  return <div className='not-found'>
    <Header />
    <img src={warning} className='warning-icon' alt="warning icon" />
    <h1>Sorry, this page isn't available</h1>
    <h2>You may have mistype the address or the page have been removed</h2>
  </div>;
};

export default NotFound;
