import React, { useEffect } from 'react'
import { colspanizer, rowspanizer } from '../util';

const ExcelTable = ({ search, data }) => {

    useEffect(() => {
        setTimeout(() => {
        rowspanizer();
        colspanizer();
        }, 100);
    });
    let columnSet = [];
    const theadFn = () => {

        for (let i = 0; i < data.length; i++) {
            let rowHash = data[i];
            for (let key in rowHash) {
                if (rowHash.hasOwnProperty(key)) {
                    if (!columnSet.includes(key)) {/*Adding each unique column names to a variable array*/
                        columnSet.push(key);
                    }
                }
            }
        }

        return (
            <tr>
                {columnSet.map(col => {
                    return (
                        <th key={col}>{col}</th>
                    )
                })}
            </tr>
        )
    }

    const tbodyFn = () => {
        return data.length > 0 && data.map((d,i) => {
            const searchMatch = d[columnSet[0]].toLowerCase().includes(search.toLowerCase())
            return (
                <tr key={`tr${i}`} style={{display: searchMatch ? '': 'none'}}>
                    {columnSet.map((ch,j) => {
                        let cell = d[ch];
                        if (cell === null) cell = "";
                        return (<td key={`${cell}${j}`} dangerouslySetInnerHTML={{ __html: cell }}></td>)
                    })}
                </tr>
            )
        })
    }

    return (
        <table className="table table-striped table-bordered">
            <thead>
                {theadFn()}
            </thead>
            <tbody>
                {tbodyFn()}
            </tbody>

        </table>
    )
}

export default ExcelTable
