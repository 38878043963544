import React, { useEffect, useState } from 'react';
import { useMathJax } from '../Providers/useMathJax';
import ExcelTable from './ExcelTable';
import Search from './Search';

const DataTable = ({ context, data }) => {
  const dataKeys = context.trim().split(' ');
  const tableKey = dataKeys[0];
  const searchKey = dataKeys[1];
  const [MathJax, updateMathContent] = useMathJax();
  const [search, setSearch] = useState('');

  useEffect(() => {
    updateMathContent();
  });

  if (!MathJax) return null;


  return data && <section>
    {searchKey && <Search tableId={tableKey} val={search} searchHandler={(e)=>setSearch(e.target.value)} />}
    <ExcelTable filter={'Children should be taught to:'} search={search} data={data[tableKey]}/>
  </section>;
};

export default DataTable;
