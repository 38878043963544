import React from 'react'
import info from '../images/information.svg'

const Message = ({children}) => {
    return (
        <div className="message">
            <div className="icon">
            <img src={info} className='info-icon' alt="information icon" />
            </div>
            <div className="text">
                <p>{children}</p>
            </div>  
            
        </div>
    )
}

export default Message