import React from 'react';

const Search = ({val, searchHandler,tableId}) => {
    return (<div className='button-controls'>
        <form role="search" className="search textarea-expand">
            <div className="input on-light-background">
                <input aria-label="search this table" id="input-1" type="text" placeholder="Search this table" className="search" onChange={(e)=>searchHandler(e,tableId)}/>
            </div>
            
        </form>
        <button className="button medium  on-light-background default-font no-print"  onClick={()=>window.print()}>Print</button>

    </div>)
};

export default Search;
