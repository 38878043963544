export const  htmlParser = (data) => {
    const doc = new DOMParser().parseFromString(data, "text/html");
    const tbl = doc.getElementsByTagName("table")[0]

    const tbody = doc.getElementsByTagName("tbody")[0]
    const th = tbody.getElementsByTagName("tr")[0]
    const htmlStr = `<thead><tr>${th.innerHTML}<tr></thead>`;

    tbl.insertAdjacentHTML('afterbegin', htmlStr);
    th.remove()
    const htmlString = doc.documentElement.innerHTML
    return htmlString
}

export const  tableToJson = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    const table = doc.getElementsByTagName("table")[0]
    const data = [];

    // first row needs to be headers
    const headers = [];
    for (let j=0; j<table.rows[0].cells.length; j++) {
        headers[j] = table.rows[0].cells[j].innerHTML === '' ? '__EMPTY':table.rows[0].cells[j].innerHTML;
    }

    // go through cells
    for (let i=1; i<table.rows.length; i++) {

        let tableRow = table.rows[i];
        let rowData = {};

        for (let k=0; k<tableRow.cells.length; k++) {

            rowData[ headers[k] ] = tableRow.cells[k].innerHTML === '' ? '__EMPTY':tableRow.cells[k].innerHTML;

        }

        data.push(rowData);
    }       

    return data;
}

export const rowspanizer = () => {
    //https://stackoverflow.com/questions/54591184/merge-table-rows-using-javascript#:~:text=If%20a%20table%20row%20values,displayed%20only%20once%20using%20javascript.
    const previousRow = {};
    const colsChanged = {};
    let leftMerged = false;
    let dark = false;

    Array.from(document.querySelectorAll("tbody tr")).forEach((tr, rowIdx) => {
      Array.from(tr.children).forEach((td, colIdx) => {
        if (
          rowIdx > 0 &&
          (colIdx === 0 || leftMerged) &&
          previousRow[colIdx].text === td.innerText
        ) {
          previousRow[colIdx].elem.setAttribute(
            "rowspan",
            ++previousRow[colIdx].span
          );
          colsChanged[colIdx] = false;
          td.remove();
          if (colIdx === 0) {
            leftMerged = true;
          }
        } else {
          previousRow[colIdx] = { span: 1, text: td.innerText, elem: td, dark };
          colsChanged[colIdx] = true;
        }
      });
      // const rowChanged = Object.values(colsChanged).every(Boolean);
      // dark = rowChanged && rowIdx > 0 ? !dark : dark;
      // if (dark) {
      //   tr.classList.add("dark");
      // }
      leftMerged = false;
    });
  }

  export const colspanizer = () => {
    //https://stackoverflow.com/questions/54591184/merge-table-rows-using-javascript#:~:text=If%20a%20table%20row%20values,displayed%20only%20once%20using%20javascript.
  
    let previousCol = {};
    let previousthCol = {};
  

    Array.from(document.querySelectorAll("tbody tr")).forEach((tr, rowIdx) => {
      Array.from(tr.children).forEach((td) => {
        if(td.innerText !== '__EMPTY'){
          const tdText = td.innerText === '__DONOTMERGE' ? td.innerHTML = '' :td.innerText;
          previousCol = { span: 1, text: tdText, elem: td };
        } else {
          previousCol.elem.setAttribute('colspan', ++previousCol.span)
          td.remove();
        }
      });
    });
    Array.from(document.querySelectorAll("thead tr")).forEach((tr, rowIdx) => {
      Array.from(tr.children).forEach((th) => {
        if(th.innerText !== '__EMPTY'){
          previousthCol = { span: 1, text: th.innerText, elem: th };
        } else {
          previousthCol.elem.setAttribute('colspan', ++previousthCol.span)
          th.remove();
        }
      });
    });
  }