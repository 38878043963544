import { tableToJson } from './util';
import XLSX from 'xlsx';



export const fetchMDContent = async (file) => {

    const response = await fetch(`./markdown/${file}.md`);
    const data = await response.text();
    return data;
};

export const fetchXLSX2JSON = async (file) => {

    const res = await fetch(`./excel/${file}.xlsx`);
    let xlsData = [];
    let htmlData = {};
    let html = null;
    const data = await res.arrayBuffer();
    const workbook = XLSX.read(new Uint8Array(data), {
        type: 'array'
    });
    workbook.SheetNames.forEach(sheet => {
        const sheetId = sheet.toLowerCase().replace(/[ _]/g,'_');
        html = XLSX.utils.sheet_to_html(workbook.Sheets[sheet], { header: '', id:sheetId })
        html = tableToJson(html)
        htmlData[sheetId] = html
        xlsData.push(html)
    });

    return htmlData;

}

