import React from 'react'
import logo from '../images/logo-new-blue.svg'

const Header = () => {
    return (
        <div className="header">
        <div className="logo">
            <img src={logo} alt="logo" />
        </div>

    </div>
    )
}

export default Header
